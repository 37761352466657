<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Tukar Faktur Report" style="z-index: 10">
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <territory-filter
            :showTitle="false"
            :full="true"
            :multiSelect="true"
          />
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Customer</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <customer-filter :full="true" :hideLabel="true" />
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Tukar Faktur Status</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="invoiceExchangeStatus"
            :options="invoiceExchangeStatusOptions"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            label="name"
            track-by="id"
            @select="onInvoiceExchangeStatusSelected"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Tukar Faktur Date</span>&nbsp;
          <feather-icon
            title="Reset"
            icon="RefreshCwIcon"
            svgClasses="w-5 h-5 hover:text-primary stroke-current"
            class="ml-2"
            @click.stop="resetDateFilter('date')"
          />
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="filterInvoiceExchangeDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Billing Date</span>&nbsp;
          <feather-icon
            title="Reset"
            icon="RefreshCwIcon"
            svgClasses="w-5 h-5 hover:text-primary stroke-current"
            class="ml-2"
            @click.stop="resetDateFilter('invoice')"
          />
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="filterInvoiceDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <!-- <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Posting Date</span>&nbsp;
          <feather-icon
            title="Reset"
            icon="RefreshCwIcon"
            svgClasses="w-5 h-5 hover:text-primary stroke-current"
            class="ml-2"
            @click.stop="resetDateFilter('posting')"
          />
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="filterInvoiceDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div> -->

      <!-- <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full">
                <span>Open Key Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                <datepicker :inline="false" v-model="openKeyDate"></datepicker>
                </div>
            </div>

            <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>SOA Number</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full"  v-model="soa_number" />
                </div>
            </div> -->

      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-button
            class="ml-4 mt-2 vx-col sm:w-2/4"
            color="primary"
            icon-pack="feather"
            icon="icon-prin"
            @click="handleDrawTable()"
            >Get Data</vs-button
          >
        </div>
      </div>
    </vx-card>
    <vx-card title="" style="z-index: 9">
      <vs-tabs :color="colorx" ref="tabs">
        <vs-tab @click="changeTab(1)" label="Tukar Faktur Report">
          <div class="con-tab-ejemplo">
            <br />
            <monitoring
              :operatingUnit="operatingUnit"
              :item="item.selected"
              :territories="TerritoryCodes"
              v-on:selectSalesOrder="updateSelectedSalesOrder"
              v-on:redirectTab="redirectTab"
              :filterInvoiceExchangeDate="filterInvoiceExchangeDate"
              :invoiceExchangeStatus="invoiceExchangeStatus"
              :filterInvoiceDate="filterInvoiceDate"
              :draw="draw"
              :openKeyDate="this.openKeyDate"
            ></monitoring>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import monitoring from "./monitoring/index.vue";
import DataTableExport from "./monitoring/DataTableExport.vue";
import DateRangePicker from "vue2-daterange-picker";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import customer from "../../../../store/modules/customer";
import customerFilter from "../../../../components/customerFilter/customerFilter.vue";
import territoryFilter from "../../../../components/operatingUnitFilter/territoryFilter.vue";
import Territory from "../../../../components/master/Territory.vue";

export default {
  components: {
    monitoring,
    Datepicker,
    DateRangePicker,
    DataTableExport,
    "customer-filter": customerFilter,
    "territory-filter": territoryFilter,
    Territory,
  },
  mounted() {
    // this.getOptionOU();
    // this.getOptionSupplier();

    const today = new Date();
    this.filterInvoiceExchangeDate.startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    this.filterInvoiceExchangeDate.endDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    this.filterInvoiceDate.startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    this.filterInvoiceDate.endDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
  },
  data: () => ({
    tabColor: [
      "success",
      "primary",
      "warning",
      "info",
      "success",
      "rgb(62, 201, 214)",
    ],
    tabIndex: 0,
    draw: 0,
    openKeyDate: new Date(),
    soa_number: null,
    customer: {},
    supplierCode: [{}],
    item: {
      options: ["All", "Open", "Close"],
      selected: "All",
    },
    optionSupplier: [{}],
    colorx: "success",
    selectedSO: {},
    TerritoryCodes: [{}],
    operatingUnit: {
      OperatingUnitTerritory: {
        Territory: {
          code: "",
        },
      },
    },
    optionOUs: [
      {
        Code: "-",
        ID: "0",
        OperatingUnitTerritory: {
          Code: "All",
          Territory: {
            ID: 0,
            code: "All",
            name: "All",
          },
        },
      },
    ],
    optionOU: [
      {
        OperatingUnitTerritory: {
          Territory: {
            code: "",
          },
        },
      },
    ],
    filterInvoiceExchangeDate: {
      startDate: null,
      endDate: null,
    },
    filterInvoiceDate: {
      startDate: null,
      endDate: null,
    },
    invoiceExchangeStatus: {
      id: 0,
      name: "All",
    },
    invoiceExchangeStatusOptions: [
      {
        id: 0,
        name: "All",
      },
      {
        id: 1,
        name: "Not Yet",
      },
      {
        id: 2,
        name: "Done",
      },
    ],
  }),
  watch: {},
  methods: {
    handleDrawTable() {
      this.draw++;
    },
    changeTab(index) {
      this.tabIndex = index;
      this.colorx = this.tabColor[index];
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    resetDateFilter(name) {
      if (name == "date") {
        this.filterInvoiceExchangeDate.startDate = null;
        this.filterInvoiceExchangeDate.endDate = null;
      } else if (name == "invoice") {
        this.filterInvoiceDate.startDate = null;
        this.filterInvoiceDate.endDate = null;
      } else if (name == "openkeydate") {
        this.openKeyDate = null;
      }
    },
    labelCustomer({ code, name }) {
      return `${code} - ${name}`;
    },
    getOptionOU() {
      // console.log(this.optionOUs)
      this.$http.get("/api/v1/master/operating-unit-territory").then((resp) => {
        this.optionOU = resp.data.records;
        let datas = resp.data.records;
        this.optionOUs = [];
        this.optionOUs.push({
          CodeOu: "AllOu",
          CodeOuTerr: "AllOuTerr",
          CodeTerr: "AllCode",
          ID: "zero",
          IDTerr: "zeroTerr",
          SelectName: "All OU/Territory",
          Name: "All",
        });
        for (var idx = 0; idx < resp.data.records.length; idx++) {
          this.optionOUs.push({
            CodeOu: datas[idx].Territory.code,
            CodeOuTerr: datas[idx].Territory.code,
            CodeTerr: datas[idx].Territory.code,
            ID: datas[idx].Territory.ID,
            IDTerr: datas[idx].Territory.ID,
            code: datas[idx].Territory.Code,
            SelectName:
              " (" +
              datas[idx].Territory.code +
              ") " +
              datas[idx].Territory.name,
            Name:
              " (" +
              datas[idx].Territory.code +
              ") " +
              datas[idx].Territory.name,
          });
        }
        this.operatingUnit = this.optionOUs[0];
      });
    },
    getOptionSupplier() {
      this.$http.get("/api/v1/master/suppliers").then((resp) => {
        if (resp.code == 200) {
          this.optionSupplier = [];
          this.optionSupplier.push({ ID: 0, Code: "All", Name: "All" });
          let data = resp.data.records;
          for (var idx = 0; idx < data.length; idx++) {
            this.optionSupplier.push(data[idx]);
          }
          this.supplier = this.optionSupplier[0];
          console.log("this.supplier", this.supplier);
        } else {
          // this.$vs.loading.close();
          this.$router.push("/");
        }
      });
    },
    updateSelectedSalesOrder(salesOrder) {
      this.selectedSO = salesOrder;
      this.changeTab(1);
      // console.log("selected", this.selectedSO);
    },
    redirectTab(index) {
      this.$refs.tabs.activeChild(index);
    },
    onChange(value) {
      let keys = Object.keys(value);
      let data = [];
      keys.forEach(function (val, i, vals) {
        if (value[i].IDTerr != "zeroTerr" && value[i].IDTerr != undefined) {
          data.push({ ID: value[i].IDTerr, Code: value[i].CodeTerr });
        } else {
          data = [
            {
              ID: 0,
              Code: "All",
            },
          ];
        }
      });
      this.TerritoryCodes = data;
    },
    onChangeCustomer(value) {
      //   let keys = Object.keys(value);
      //   let data = [];
      //   console.log("value", value);
      //   keys.forEach(function (val, i, vals) {
      //     if (value[i].ID != 0) {
      //       data.push({
      //         ID: value[i].ID,
      //         Code: value[i].Code,
      //         Name: value[i].Name,
      //       });
      //     } else {
      //       data = [
      //         {
      //           ID: 0,
      //           Code: "All",
      //           Name: "All",
      //         },
      //       ];
      //     }
      //   });
      //   this.supplierCode = data;
      this.$store.commit("customerFilter/setSelectedCustomers", value);
    },
    onSelect(val, i) {
      if (val.ID == "zero") {
        this.getOptionOU;
      }
      console.log("OnSelect");
      console.log(val.ID);
      console.log(i);
    },
    onInvoiceExchangeStatusSelected(val) {
      if (val.name === "Not Yet") {
        this.filterInvoiceExchangeDate.startDate = null;
        this.filterInvoiceExchangeDate.endDate = null;
      }else{
        this.filterInvoiceExchangeDate.startDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        );
        this.filterInvoiceExchangeDate.endDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        );
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("customerFilter/resetState");
    // this.$store.commit("operatingUnit/resetState");
  },
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>
